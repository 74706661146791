import * as React from "react"
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Profile = ({ name, description, image, mobile }) => {
    if (mobile) {
        return (
            <div>
                <AnimationOnScroll animateOnce animateIn="animate__jackInTheBox">
                    <div style={{ display: "flex", justifyContent: "center" }}>{image}</div>
                </AnimationOnScroll>
                <h2 style={{ justifyContent: "center", display: "flex", marginBottom: "0px" }}>{name}</h2>            <h3>{description}</h3>
            </div >
        )

    }
    else return (
        <div>
            <AnimationOnScroll animateOnce animateIn="animate__jackInTheBox">
                <div style={{ justifyContent: "center", display: "flex", minHeight: "750px", maxHeight: "750px" }}>{image}</div>
            </AnimationOnScroll>
            <h2 style={{ justifyContent: "center", display: "flex", marginBottom: "0px" }}>{name}</h2>            <h3>{description}</h3>
        </div >
    )
}

export default Profile;