export const AboutStrings = {
    title: "About Us",
    description: "We are a best friend duo with a deep passion for organizing and planning, and we believe that every special event deserves to be stunning. With this philosophy in mind we founded Sparkle Celebrations in 2018, and have since gained many valuable connections with industry-leading experts and vendors. We promise to work day and night to guarantee that your next event will be filled with unforgettable moments.",
    description2: "xoxo, Charlotte & Katie",
    charlotte: {
        name: "Charlotte Alan, Co-Owner",
        title: "Co-Owner",
        description: "Hey, y'all! I'm a dog mom originally from Virginia and I have experience working as both a wedding planner and corporate event coordinator back home. I love listening to podcasts, reading self-help books (cheesy, I know!) and cuddling with my no-eyed pup, Rhythm Delilah. I always stay busy so I can have an excuse NOT to play Monopoly just in case someone asks (sorry, Katie!)."
    },
    katie: {
        name: "Katie Thill, Co-Owner",
        title: "Co-Owner",
        description: "I love spending time with my family I have two children who are the light of my life and my world. I enjoy making culinary arts and inspiring new charcuterie ideas! I also enjoy a good board game with my family and friends. Creating and designing are two of my most favorite things that I am so passionate about. "
    }
}