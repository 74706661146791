import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Seo from "../components/seo"
import Profile from "../components/Profile"
import styled from "styled-components"
import { AboutStrings as About } from "../content/AboutText"
import { Colors, Padding } from "../components/Styles"
import { useEffect, useState } from 'react'
import { AnimationOnScroll } from 'react-animation-on-scroll';

const Text = styled.h1`
margin: 10px;
justify-content: center;
display: flex;
`

const Texth2 = styled.h2`
margin: 10px;
justify-content: center;
display: flex;
`

const MobileH1 = styled.h1`
font-size: 50px;
padding-top: 10px;
`

const MobileH2 = styled.h2`
font-size: 18px;
`

const AboutPage = () => {
  const profilePics = {
    charlotte: <StaticImage src="../images/About/Charlotte.png" />,
    katie: <StaticImage src="../images/About/Katie.png" />,
  }

  const [isDesktop, setDesktop] = useState(null)
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setDesktop(window.innerWidth > 920)
    }
  }, [])

  const updateMedia = () => {
    setDesktop(window.innerWidth > 920)
  }

  useEffect(() => {
    window.addEventListener("resize", updateMedia)
    return () => window.removeEventListener("resize", updateMedia)
  })

  if (isDesktop !== null && isDesktop === true) {
    return (<div>
      <div style={{ display: "flex", backgroundColor: Colors.cream }}>
        <StaticImage
          src="../images/about.jpg"
          loading="eager"
          quality={100}
          objectFit="cover"
          formats={["auto", "webp", "avif"]}
          alt="A photo from a wedding hosted by Sparkle Celebrations."
          style={{ zIndex: "90", width: "50%" }} />
        <div style={{ width: `45%`, display: 'flex', flexDirection: 'column', justifyContent: 'center', paddingLeft: Padding.gutter, paddingTop: Padding.banner, paddingBottom: Padding.banner }}>
          <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft">
            <h1>{About.title}</h1>
            <h2>{About.description}</h2>
            <h2>{About.description2}</h2>
          </AnimationOnScroll>
        </div>
      </div>
      <div style={{ backgroundColor: Colors.purple, padding: Padding.banner, color: Colors.white }}>
        <AnimationOnScroll animateOnce animateIn="animate__fadeInUp">
          <Text>Meet the Team</Text>
          <Texth2>Two best friends living OUR dream to make YOUR dreams come true!</Texth2>
        </AnimationOnScroll>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center', backgroundColor: Colors.cream, padding: Padding.gutter }}>
        <div style={{ display: "inline-flex" }}>
          <Profile name={About.charlotte.name} title={About.charlotte.title} description={About.charlotte.description} image={profilePics.charlotte} />
          <div style={{ width: "25%" }} />
          <Profile name={About.katie.name} title={About.katie.title} description={About.katie.description} image={profilePics.katie} />
        </div>
      </div>
    </div >)
  }
  else if (isDesktop !== null && isDesktop === false) {
    return (
      <div style={{ paddingTop: "95px" }}>
        <div style={{ width: `100%`, justifyContent: 'center', padding: Padding.mobileGutter, paddingTop: Padding.banner, paddingBottom: Padding.banner }}>
          <AnimationOnScroll animateOnce animateIn="animate__fadeInLeft">
            <MobileH1>{About.title}</MobileH1>
            <MobileH2>{About.description}</MobileH2>
            <MobileH2>{About.description2}</MobileH2>
          </AnimationOnScroll>
        </div>
        <div style={{ backgroundColor: Colors.purple, padding: Padding.banner, color: Colors.white }}>
          <AnimationOnScroll animateOnce animateIn="animate__fadeInUp">
            <MobileH1 style={{ display: "flex", justifyContent: "center" }}>Meet the Team</MobileH1>
            <MobileH2 style={{ display: "flex", justifyContent: "center", textAlign: "center" }}>Two best friends living OUR dream to make YOUR dreams come true!</MobileH2>
          </AnimationOnScroll>
        </div>
        <div style={{ padding: Padding.mobileGutter }}>
          <Profile mobile style={{ width: "100%" }} name={About.charlotte.name} title={About.charlotte.title} description={About.charlotte.description} image={profilePics.charlotte} />
          <Profile mobile style={{ width: "100%" }} name={About.katie.name} title={About.katie.title} description={About.katie.description} image={profilePics.katie} />
        </div>
      </div>
    )
  }
}

export const Head = () => <Seo title="About" />

export default AboutPage